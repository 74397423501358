.directory-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media (max-width: 1000px) {
    .directory-container {
        display: flex;
    }
}