.project-card {
    display: grid;
    grid-template-columns: 45%;
    grid-gap: 4rem;
    margin-bottom: 2rem;
    padding: 6rem;

    .projects__list-item-details {

        .projects__list-item-img-cont {
            display: flex;
            align-items: center;
            justify-content: center;


            .project-image {
                max-height: 50vh;
                max-width: 100%;
                display: block;
                border-radius: 5px;
                border: 1px solid rgba(0, 0, 0, 0.1);

            }
        }
    }



    .project-title {
        margin: 2rem 0 1rem 0;
        font-size: 3.4rem;
        letter-spacing: 1px;
        line-height: 1.3;
        color: #000;
        font-weight: 400;
    }

    .project-text {
        margin-bottom: 1rem;
        font-size: 1.2rem;
        color: #444 !important;
        line-height: 1.5;

        span {
            margin-bottom: 1.5rem;
            display: block;
        }

        span:last-child {
            margin-bottom: 0;
        }
    }


    .button-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}

.projects__list-item--inv {
    justify-content: flex-end;
}

@media (max-width: 75em) {
    .project-card {
        grid-template-columns: 100%;

        .projects__list-item-details {

            .projects__list-item-img-cont {


                .project-image {
                    max-width: 95%;
                    display: block;
                    position: relative;
                    border-radius: 5px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                }
            }
        }
    }

    .projects__list-item--inv {
        justify-content: flex-end;
    }
}

@media (max-width: 380px) {
    .project-card {
        max-width: 95%;
        padding: 1rem;

        .project-title {
            font-size: 2rem;
            text-align: center;
        }
    }
}