@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

:root {
    --background:#2c2c2c;
}

.hover-button-container {
    margin: 0;
    padding: 0;
    box-sizing: 0;
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    width: 100%;
    display: flex;
    justify-content: center;

    .background {
        background-color: var(--background);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25vh;
        margin: 10px;
        width: 50%;

        .button {
            color: #ffffff;
            cursor: pointer;
            font-size: 1rem;
            line-height: 2.5rem;
            max-width: 160px;
            letter-spacing: 0.3rem;
            font-weight: 600;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 1s ease-in;

            svg {
                height: 60px;
                left: 0;
                top: 0;
                transform: translate(-28%, -20%);
                position: absolute;
                width: 200%;

                rect {
                    fill: none;
                    stroke: #ffffff;
                    stroke-width: 2;
                    stroke-dasharray: 450, 0;
                    transition: all 0.5 linear;
                }
            }
        }

        .button:hover {
            color: #ff9950;

            svg {
                rect {
                    stroke-width: 5;
                    stroke-dasharray: 20, 300;
                    stroke-dashoffset: 48;
                    stroke: #ff9950;
                    transition: all 2s cubic-bezier(0.22, 1, 0.25, 1);
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .hover-button-container {

        .background {
            width: 95%;
        }
    }
}