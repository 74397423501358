@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.section-header-container {
    font-family: Rubik, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;

    .section-header-title {
        width: 80%;
        margin-left: 5%;
        font-size: 1.5rem;
        margin-top: 7rem;
    }

    .section-header-text {
        width: 80%;
        font-size: 2rem;
    }
}