@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.project-highlight-container {
    font-family: Rubik, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    overflow-x: hidden;

    .section-header {
        width: 80%;
        margin-left: 5%;
        font-size: 1.5rem;
        margin-top: 7rem;
    }

    .section-text {
        width: 80%;
        font-size: 3rem;
    }
}

