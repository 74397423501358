.footer-container {
    background: #222222;
    color: white;
    padding-bottom: 25px;
    margin-bottom: 0;
    text-align: center;
    position: relative;

    .logo-container {
        display: flex;
        justify-content: center;
        margin-top: 25px;

        .logo {
            height: 100px;
            transition: all 0.5s ease;
        }

        .logo:hover {
            transform: scale(1.1);
            filter: brightness(80%);
        }
    }

    .links-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        justify-items: center;

        @media (max-width: 600px) {
            grid-template-columns: 1fr; // Switch to a single column
        }

        .page-links-container,
        .social-links-container {
            display: flex;
            flex-direction: column;
            padding: 25px;
            align-items: center;
        }

        .link-title {
            margin-bottom: 20px;
        }

        .footer-link {
            text-decoration: none;
            margin: 5px 0;
            color: white;
        }

        .footer-link:hover {
            text-decoration: underline;
            transform: scale(125%);
        }
    }

    .divider-copyright {
        width: 50%;
        border-color: rgba(255, 255, 255, 0.2);
    }

    .copyright {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px 0;
    }
}