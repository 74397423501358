@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@500&display=swap');

:root {
    --footer-background: white;
    --text-color: #111420;
    --text-gray: #e1e1e1;
    --text-heading-gray: #b9b9b9;
}

footer {
    font-size: 16px;
    font-family: "Red Hat Display", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    background-color: var(--footer-background);
    min-width: 300px;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;

    .footer-line {
        display: block;
        width: 100%;
        height: 8px;
        background: linear-gradient(
            90deg, #5da8ff,
            #605dff 50%, #ad63f6
        );
    }

    .footer-wrapper {
        display: flex;
        background-color: var(--footer-background);
        flex-direction: column;
        max-width: 1320px;
        padding: 16px;

        .footer-top {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 16px 0;
            justify-content: space-between;

            .footer-headline {
                h2 {
                    margin: 0px;
                }

                p {
                    margin: 12px 0px;
                }
            }

            .footer-subscribe {
                display: flex;
                gap: 12px;
                flex-wrap: wrap;

                input {
                    border: 1px solid var(--text-gray);
                    color: var(--text-color);
                    min-height: 36px;
                    font-size: 1.2rem;
                    flex: 1 0 120px;
                    padding: 0 12px;
                    border-radius: 8px;
                }

                button {
                    cursor: pointer;
                    background-color: var(--text-color);
                    border-radius: 8px;
                    color: white;
                    font-size: 1.25rem;
                    min-width: 120px;
                    min-height: 36px;
                    flex: 1 0 80px;
                    white-space: nowrap;
                    padding: 8px 12px;
                    border: 0px;
                    outline: none;
                }
            }
        }

        .footer-columns {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            flex: 2 0 140px;
            width: 100%;
            gap: 16px;
            padding: 24px 8px 16px 8px;
            margin: 0 auto;
            border-top: 1px solid var(--text-gray);

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                a {
                    color: var(--text-color);
                    text-decoration: none;
                }

                a:hover {
                    text-decoration: underline;
                }

                li {
                    margin-bottom: 16px;
                }
            }

            h3 {
                color: var(--text-heading-gray);
                margin-top: 0;
                margin-bottom: 25px;
                font-size: 1.125rem;
            }

            section {
                min-width: 150px;
            }

            .footer-logo {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                min-width: 180px;
                gap: 8px;

                svg {
                    stroke: var(--text-color);
                    stroke-width: 8px;
                    width: 50px;
                }
            }
        }

        .footer-bottom {
            text-align: center;
            border-top: 1px solid var(--text-gray);
            margin-top: 48px;
            display: flex;
            align-items: center;
            width: 100%;
            gap: 8px;
            padding: 16px 0px;
            flex-wrap: wrap;
            justify-content: space-between;

            small {
                font-size: 16px;
                margin: 0 4px;
            }

            .social-links {
                display: flex;
                flex-direction: row;
                gap: 12px;
            
                img {
                    width: 24px;
                    height: 24px;
                    transition: all 0.2s ease-in-out;
                }

                img:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .footer-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 16px 8px 32px 8px;
    }

    .footer-bottom {
        display: flex;
        flex-direction: column-reverse;
        align-items: space-between;
        justify-content: center;
        margin: 0 auto;
    }
}