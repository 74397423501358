:root {
    --navColor1: rgba(255, 0, 108, 0.5);
    --navColor2: rgba(0, 255, 147, 0.5);
    --navColor3: rgba(0, 108, 255, 0.5);
    --navColor4: rgba(147, 0, 255, 0.5);
    --navColor5: rgba(255, 147, 0, 0.5);
    --navColor6: rgba(0, 129, 163, 0.5);
}

.navigation {
    height: 120px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #222222;
    z-index: 1;
    position: relative;
    top: 0;

    .logo-container {
        height: 90%;
        width: 70px;
        padding: 25px;
        color: white;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
            height: 90%;
            position: relative;
            left: 50px;
            top: calc(-20%);
            transition: all .5s ease;
        }

        .logo:hover {
            transform: scale(110%);
            filter: brightness(80%);
        }
    }

    .nav-links-container {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 15px;
        

        .menu-toggle {
            display: none;
        }

        .nav-link {
            margin: 10px 15px;
            padding: 5px 0px;
            cursor: pointer;
            color: white;
            text-decoration: none;
            font-weight: 700;
            display: inline-block;
            position: relative;
        }

        .nav-link::before,
        .nav-link::after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 2px;
            transform: scaleX(0);
            transition: transform 0.25s;
        }

        .nav-link::before {
            top: -3px;
            transform-origin: left;
            background-color: #ff0003;
        }

        .nav-link::after {
            bottom: -3px;
            transform-origin: right;
            background-color: #0083ff;
        }

        .nav-link:hover::before,
        .nav-link:hover::after,
        .nav-link:active::before,
        .nav-link:active::after {
            transform: scaleX(1);
            cursor: pointer;
        }

        // .nav-link:hover {
        //     text-decoration: underline;
        //     color: #bbbbbb;
        // }

        .nav-links-menu {
            display: flex;
            flex-direction: column;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 3rem;
            background: rgba(0, 0, 0, 0.9);
            height: 100vh;
            width: 100vw;
            justify-content: center;
            align-items: center;
            z-index: 10;

            .nav-link-menu {
                color: rgb(255, 255, 255);
                text-decoration: none;
                height: 100%;
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
            }

            .nav-link-menu:hover {
                text-decoration: underline;
            }

            .close-menu {
                position: fixed;
                top: 10px;
                right: 10px;
            }

            .close-menu:hover {
                filter: brightness(50%);
                transform: scale(75%);
            }

            .nav-link-1 {
                background: var(--navColor1);
            }

            .nav-link-2 {
                background: var(--navColor2);
            }

            .nav-link-3 {
                background: var(--navColor3);
            }

            .nav-link-4 {
                background: var(--navColor4);
            }

            .nav-link-5 {
                background: var(--navColor5);
            }

            .nav-link-6 {
                background: var(--navColor6);
            }
        }

        .hidden {
            display: none;
        }
    }

    @media (max-width: 1150px) {
        .logo-container {
            height: 60%;
            padding: 0;
            margin: 10px;
            
            .logo {
                top: calc(50px - 50%);
                left: 25px;
            }
            
        }
        
        .nav-links-container {
            position: relative;

            .menu-toggle {
                display: flex;
                margin-right: 25px;
                background: transparent;
                border: none;
                cursor: pointer;
            }

            .menu-toggle:hover {
                filter: brightness(80%);
            }

            .nav-links {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                background: #222222;
                display: flex;
                flex-direction: column;
                opacity: 0;
                pointer-events: none;
                padding: 10px 0;
                transition: all 0.3s ease;

                .nav-link {
                    /* additional styles for the mobile menu */
                }
            }

            .active {
                .nav-links {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }
}

@media (max-width: 360px) {
    .navigation {
        width: 100vw;
    }
}