.experience-container {
    width: 80%;
    margin: 0 auto;

    .exp-title {
        text-align: center;
        margin-top: 35px;
    }


    .experience {
        border: 1px solid #808080;
        padding: 20px;
        margin: 25px 0;
        border-radius: 5px;
        box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.5);

        .header {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;

            h4:not(:first-child)::before {
                content: '|';
                margin-left: 5px;
                margin-right: 5px;
            }
        }

        ul {
            font-size: 18px;
        }
    }
}

@media (max-width: 1000px) {
    .experience-container {
        .experience {
            .header {
                display: block;

                h4 {
                    text-align: center;
                }
                
                h4:not(:first-child)::before {
                    content: '';
                }
            }
        }
    }
}