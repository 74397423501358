.contact-container {
    position: relative;

    form {
        width: 80%;
        margin: 0 auto 50px;
        display: flex;
        flex-direction: column;
        font-size: 24px;
        background-color: #00dbff;
        background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23000c3c' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
        padding: 20px;
        box-shadow: 0px 10px 15px 12px rgba(0, 0, 0, 0.5);

        .input-field {
            font-size: 24px;
            padding: 15px 25px;
            border: none;
            border-radius: 7px;
            font-family: sans-serif;
            background: rgba(255, 255, 255, 0.8);
        }

        input {
            margin: 5px 10px;
        }

        textarea {
            margin: 5px 10px 15px;
            height: 200px;
        }
    }

    button {
        margin: 10px;
        font-family: inherit;
        letter-spacing: 1px;
        font-weight: 700;
        border: none;
        box-shadow: 0 5px 5px 3px rgba(0, 0, 0, 0.5);
        cursor: pointer;
        padding: 1rem 2rem;
        font-size: 1.5rem;
        border-radius: 5px;
        margin-top: 0px;
        background: #ff005b;
        color: white;
    }

    button:hover {
        filter: brightness(80%);
        color: black;
    }
}