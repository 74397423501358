:root {
    --color1: #4356c3;
    --color2: #4396c3;
}

.btn {
    margin: 15px;
    font-family: inherit;
    letter-spacing: 1px;
    font-weight: 700;
    border: none;
    box-shadow: none;
    cursor: pointer;
    padding: 1.6rem 3.5rem;
    font-size: 1.5rem;
    border-radius: 5px;
    border: 2px solid var(--color1);
    margin-top: 0px;

    a {
        color: white;
        text-decoration: none;
    }
}

.btn:hover {
    filter: brightness(150%);
}

.live-link {
    background: var(--color1);
    box-shadow: 0 5px 15px 0 rgb(0 0 0 /15%);
}

.code-link {
    background: transparent;
    border: 3px solid var(--color1);

    a {
        color: var(--color1);
    }

}

.app {
    background: var(--color1);
}

.view-more {
    background: var(--color2);
    border: 2px solid var(--color2);
}

.view-more:hover {
    filter: brightness(80%);
}

@media (max-width: 80em) {
    .btn {
        font-size: 1rem;
        padding: 1.8rem 3rem;
    }
}

@media (max-width: 380px) {
    .btn {
        padding: 1rem 2rem;
    }
}