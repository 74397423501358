.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 50%;
    background: #222;
    margin: 10px;

    .loader_ball {
        display: inline-block;
        width: 25px;
        height: 25px;
        background-color: #ffffff;
        margin-inline: 6px;
        border-radius: 50%;
        animation: bounce 450ms alternate infinite;
    }

    .loader_ball:nth-child(2) {
        animation-delay: 150ms;
    }

    .loader_ball:nth-child(3) {
        animation-delay: 300ms;
    }

    @keyframes bounce {
        from {
            transform: scaleX(1.25);
        }
        to {
            transform: translateY(-50px) scale(1);
        }
    }
}

@media (max-width: 1000px) {
    .loader {
        width: 95%;
    }
}