.modal-container {
    box-sizing: border-box;
    margin: 10px;
    padding: 0;
    font-family: sans-serif;
    height: 50vh;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(500, 613, 65, 1);
    font-family: 'poppings', sans-serif;
    position: relative;

    .modal-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;

        .modal {
            background-color: #ffffff;
            padding: 20px;
            border-radius: 5px;
            max-width: 350px;
            opacity: 0;
            transform: translateY(-50px);
            transition: opacity 0.3s ease, transform 0.3s ease;

            .modal-header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .close-modal-btn {
                    background-color: transparent;
                    border: none;
                    color: #333333;
                    font-size: 24px;
                    cursor: pointer;
                }

                .close-modal-btn:hover {
                    color: #ff0000;
                }

                .close-modal-btn:focus ~ .modal-overlay {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }

    .open-modal-btn {
        padding: 10px 20px;
        background-color: #333333;
        color: #ffffff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-weight: bold;
        font-size: 18px;
    }

    .open-modal-btn:focus ~ .modal-overlay {
        opacity: 1;
        visibility: visible;

        .modal {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .attribute {
        position: absolute;
        bottom: 10px;
    }
}

@media (max-width: 1000px) {
    .modal-container {
        width: 95%;
    }
}

@media (max-width: 480px) {
    .modal-container {
        .modal-overlay {
            .modal {
                max-width: 95%;
                margin: 2.5%;
            }
        }
    }
}