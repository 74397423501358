:root {
    --color1: #00beef;
}

.card {
    position: relative;
    isolation: isolate;
    width: 425px;
    height: 425px;
    background-color: #000000;
    border-radius: 8px;

    .card_image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0 10%;
        border-radius: inherit;
        transition: transform 250ms;
    }

    .card_body {
        position: absolute;
        inset: 0;
        z-index: -1;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 24px;
        color: var(--color1);

        div {
            .card_category {
                color: #ffffff;
            }
        }

        .card_button {
            display: inline-block;
            text-decoration: none;
            padding: 10px 15px;
            border-radius: 4px;
            background-color: var(--color1);
            color: #000000;
            text-align: center;
        }
    }
}

.card:hover .card_image {
    transform: translateY(-112px) scale(0.75);
}

@media (max-width: 1000px) {
    .card {
        width: 300px;
        height: 425px;

        .card_image {
            width: 100%;
            height: 100%;
        }

        .card_body {
            padding: 24px;

            .card_button {
                padding: 10px 15px;
            }
        }
    }
}

@media (max-width: 480px) {
    .card {
        width: 200px;
        height: 325px;

        .card_image {
            width: 100%;
            height: 100%;
        }

        .card_body {
            padding: 12px;

            .card_button {
                padding: 5px 7.5px;
            }
        }
    }
}