.about-container {
    
    .about-section-container {
        display: grid;
        grid-gap: 0.5rem;
        width: 90%;
        margin: 0 auto;

        grid-template:
            "story pic"
            "story skills"
            "icons icons"
            "exp exp"
            "edu cert"
            "scuba scuba";

        .container {
            // border: 1px solid red;
        }

        .profile-picture-container {
            grid-area: pic;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .my-story-container {
            grid-area: story;
            margin-right: 50px;

            .story-title {
                width: 100%;
                text-align: center;
            }

            p {
                font-size: 1.2rem;
            }
        }

        .experience-container {
            grid-area: exp;
        }

        .education-container {
            grid-area: edu;
        }

        .certifications-container {
            grid-area: cert;
        }
        .scuba-certifications-container {
            grid-area: scuba;
        }
    }
}

@media (max-width: 1000px) {
    .about-container {
        .about-section-container {
            grid-template:
                "pic"
                "story"
                "skills"
                "icons"
                "exp"
                "edu"
                "cert"
                "scuba"
            ;

            .skills-icon-container {
                width: 95%;
            }

            .my-story-container {
                margin: 0;
            }

            .skills-icon-container {
                display: none;
            }

            
        }
    }
}

@media (max-width: 550px) {
    .about-container {
        .about-section-container {
            .my-skills-container {
                flex-wrap: wrap;
            }

            .skills-icon-container {}
        }
    }
}