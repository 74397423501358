.my-skills-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    grid-area: skills;

    .skills-title {
        width: 100%;
        text-align: center;
    }

    .skill-box {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: .2rem 1rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        background: #eee;
        color: #666;
        border-radius: 5px;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
        max-height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}