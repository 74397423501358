.category-container {
    min-width: 30%;
    min-height: 240px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    margin: 0 7.5px 15px;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);

    &:hover {
        cursor: pointer;

        & .background-image {
            transform: scale(1.1);
            transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
        }

        & .category-body-container {
            opacity: 0.9;
        }
    }

    &.large {
        height: 380px;
    }

    &:first-child {
        margin-right: 7.5px;
    }

    &:last-child {
        margin-left: 7.5px;
    }

    .background-image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .category-body-container {
        min-height: 150px;
        padding: 0 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid black;
        background-color: white;
        opacity: 0.7;
        position: absolute;
        border-radius: 5px;
        max-width: 25%;

        h2 {
            font-weight: bold;
            margin: 0 6px 0;
            font-size: 22px;
            color: #4a4a4a;
        }

        h5 {
            font-weight: bold;
            margin: 0 6px 0;
            font-size: 15px;
            color: #4a4a4a;
        }

        p {
            font-weight: lighter;
            font-size: 16px;
        }

        a {
            text-decoration: none;
            text-align: center;
        }
    }
}

@media (max-width: 1064px) {
    .category-container {
        width: 100%;
        
        .category-body-container {
            padding: 0 5px;
            max-width: 80%;
            
            h2 {
                font-size: 16px;
            }

            h5 {
                font-size: 12px;
            }
        }
    }
}

@media (max-width: 380px) {
    .category-container {
    
    
            .category-body-container {
    
                h2 {
                    font-size: 12px;
                }
            }
        }
}