.shop-container {
    margin: 25px;

    .disclosure {
        text-align: center;
        background: #ddd;
        color: red;
        width: 50%;
        margin: 20px auto;
        font-size: 20px;
        padding: 15px;
        border: 1px solid red;
        border-radius: 5px;
        box-shadow: 1px 5px 10px 1px rgba(0, 0, 0, 0.5);
    }

    .disclosure:hover {
        background: white;
        color: black;
        border-color: black
    }

    .search-inputs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 35px;
    }
}

@media (max-width: 650px) {
    .search-inputs {
    }
}