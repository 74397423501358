@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.landing-page-container {
    width: 100vw;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #59C173;
    background: linear-gradient(#22ebd8, #4959c7);
    font-family: Rubik, sans-serif;
    line-height: 80px;
    overflow: hidden;

    .section-header {
        width: 80%;
        margin-left: 5%;
        color: white;
        font-size: 1.5rem;
        z-index: 0;
        position: relative;
        top: 50px;
    }

    .section-text {
        width: 80%;
        font-size: 3rem;
        color: white;
        z-index: 0;
        position: relative;
        top: 50px;
}

    .bubble-container {
        
        .bubble {
            height: 60px;
            width: 60px;
            border-radius: 50px;
            position: absolute;
            top: 10%;
            left: 10%;
            animation: 4s linear infinite;
            border: 2px solid rgba(255, 255, 255, 0.7);
            z-index: 0;
            
            .dot {
                position: absolute;
                height: 10px;
                width: 10px;
                background: rgba(255, 255, 255, 0.5);
                top: 10%;
                right: 20%;
                border-radius: 50%;
            }
        }

        .bubble:nth-child(1) {
            top: 50%;
            left: 11.11%;
            animation: animate 8s linear infinite;
        }

        .bubble:nth-child(2) {
            top: 110%;
            left: 22.22%;
            animation: animate 10s linear infinite;
        }

        .bubble:nth-child(3) {
            top: 66%;
            left: 33.33%;
            animation: animate 3s linear infinite;
        }

        .bubble:nth-child(4) {
            top: 60%;
            left: 44.44%;
            animation: animate 7s linear infinite;
        }

        .bubble:nth-child(5) {
            top: 35%;
            left: 55.55%;
            animation: animate 8.5s linear infinite;
        }

        .bubble:nth-child(6) {
            top: 80%;
            left: 66.66%;
            animation: animate 9s linear infinite;
        }

        .bubble:nth-child(7) {
            top: 60%;
            left: 77.77%;
            animation: animate 4s linear infinite;
        }

        .bubble:nth-child(8) {
            top: 100%;
            left: 88.88%;
            animation: animate 5.5s linear infinite;
        }
    }
    
    @keyframes animate {
        0% {
            transform: scale(0) translateY(0) rotate(70deg);
        }
        100%{
            transform: scale(1.3) translateY(-400px) rotate(180deg);
        }
    }
}

.modal-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    margin-top: 50px;

    .close-btn {
        position: fixed;
        top: 0;
        right: 0;
        font-size: 3rem;
        padding: 15px;
        border-radius: 5px;
        margin: -35px 15px;
        color: white;
        background: transparent;
        border: none;
    }

    .close-btn:hover {
        background: #999;
        color: black;
    }
}

@media (max-width: 768px) {
    .landing-page-container {
    
    
            .section-header {
                margin-top: 10%;
                font-size: 1.5rem;
            }
    
            .section-text {
                font-size: 2rem;
            }
        }
}

@media (max-width: 315px) {
    .landing-page-container {
        .section-text {
            font-size: 1.5rem;
        }
    }
}