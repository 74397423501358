.play-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .play-component {
        display: flex;
        justify-content: center;
        align-items: center;

        
    }
        
    .play-title {
        width: 50%;
        font-size: 24px;
    }

    .play-divider {
        border-color: rgba(0, 0, 0, 0.3);
        width: 75%;
        margin: 50px 0 20px;
    }

    .bootstrap-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        button {
            margin: 5px;
        }
    }
}

@media (max-width: 1000px) {
    .play-container {
        .play-title {
            width: 95%;
        }
    }
}