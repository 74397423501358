:root {
    --color-glow: #d83bff;
}

.glowing-menu {
    margin: 10px;
    // original margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    display: flex;
    justify-content: center;

    section {
        position: relative;
        // width: 100%;
        // height: 100vh;
        width: 50%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #222222;
        overflow: hidden;

        ul {
            position: relative;
            display: flex;
            flex-direction: column;
            padding-left: 0;

            li {
                list-style: none;
                padding: 25px;
                
                a {
                    position: relative;
                    display: block;
                    text-decoration: none;
                    text-align: center;
                    text-transform: uppercase;
                    color: #ffffff;
                    font-size: 4em;
                    font-weight: 700;
                    transition: 0.5s;
                    transition-delay: 0.5s;
                }

                a:hover {
                    color: rgba(255, 255, 255, 0.1);
                    transition-delay: 0.5s;
                }

                a::before {
                    content: attr(data-text);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 0.35em;
                    color: var(--color-glow);
                    text-shadow: 0 0 10px var(--color-glow), 0 0 30px var(--color-glow), 0 0 80px var(--color-glow);
                    font-weight: 500;
                    letter-spacing: 40px;
                    white-space: nowrap;
                    text-align: center;
                    opacity: 0;
                    transition: 0.5s;
                }
            }

            li:hover {
                a::before
                {
                    opacity: 1;
                    letter-spacing: 6px;
                    transition-delay: 0.5s;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .glowing-menu {
        section {
            width: 95%;
        }
    }
}

@media (max-width: 480px) {
    .glowing-menu {
        section {

            ul {

                li {
                    padding: 15px;
                    
                    a {
                        
                        font-size: 2em;
                    }
                }
            }
        }
    }
}