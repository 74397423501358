.search-box {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 25px;
    margin-bottom: 25px;

    .search-input {
        font-size: 18px;
        padding: 15px;
        border-radius: 5px;
        box-shadow: 5px 5px 20px 2px rgba(0, 0, 0, 0.5);
        background-color: whitesmoke;
        border: 1px solid black;
    }
}

@media (max-width: 650px) {
    .search-box {
        margin: 15px auto 30px;
    }
}