.select-box {
    display: flex;
    margin-left: 25px;
    margin-bottom: 25px;

    .selection {
        font-size: 18px;
        padding: 15px;
        border-radius: 5px;
        box-shadow: 0px 5px 20px 2px rgba(0, 0, 0, 0.5);
        background-color: whitesmoke;
        border: 1px solid black;

        option {

        }
    }
}

@media (max-width: 650px) {
    .select-box {
        margin: 15px auto;
    }
}