.skills-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: icons;

    ul {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .skill-icon {
            font-size: 80px;
            padding: 20px 50px;
            margin: 15px;
            border-radius: 5%;
            color: #00beef;
            background-color: #333333;
            box-shadow: 0px 5px 10px 7px rgba(0, 190, 238, 0.7);
            transition: all .5s ease-in-out;
        }

        .skill-icon:hover {
            filter: brightness(80%);
        }

        .skill-icon:hover:nth-child(odd) {
            color: #beef00;
            box-shadow: 0px 5px 10px 7px rgba(190, 238, 0, 0.7);
        }

        .skill-icon:hover:nth-child(even) {
            color: #ef3100;
            box-shadow: 0px 5px 10px 7px rgba(239, 49, 0, 0.7);
        }
    }
}