.menu-blur-container {    
    background: #222222;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ul:hover {
        li {
            filter: blur(2px);
        }
    }

    ul {
        display: flex;
        gap: 1em;
        padding: 0px;

        li {
            list-style-type: none;
            display: block;
            color: #ffffff;
            transition: 0.5s;
            letter-spacing: 0.5px;
            font-weight: bold;
            padding: 15px;
            border-radius: 5px;
        }

        li:hover {
            filter: blur(0);
            cursor: pointer;
            letter-spacing: 1px;
            background: linear-gradient(90deg, rgb(50, 50, 245) 35%, rgb(255, 0, 221) 100%);
        }
    }
}

@media (max-width: 1000px) {
    .menu-blur-container {
        width: 95%;
    }
}

@media (max-width: 480px) {
    .menu-blur-container {
        ul {
            flex-direction: column;
            text-align: center;
        }
    }
}