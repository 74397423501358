:root {
    --border-radius: 15px;
}

.responsive-card {
    display: flex;
    // border: 1px solid rgba(0, 0, 0, 0.5);
    box-shadow: 0px 15px 25px 15px rgba(0, 0, 0, 0.5);
    border-radius: var(--border-radius);
    margin: 10px;

    .thumb {
        width: 200px;
        height: 200px;
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
        background-color: rgba(0, 0, 255, 0.5);
    }

    .responsive-content {
        width: 300px;
        margin: 0 25px;
    }
}

@media (max-width: 1000px) {
    .responsive-card {
        flex-wrap: wrap;
        flex-direction: column;

        .thumb {
            border-top-left-radius: var(--border-radius);
            border-top-right-radius: var(--border-radius);
            border-bottom-left-radius: 0;
            background-color: rgba(0, 0, 255, 0.5);
            width: 100%;
        }
    }
}