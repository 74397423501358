.hover-cards-gallery {
    display: flex;
    left: calc(50% - 385px);

    .hover-card {
        position: relative;
        left: 0px;
        width: 110px;
        padding: 8px;
        background-color: white;
        border-radius: 8px;
        transition: 1s ease-in-out;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 25px;
        height: 160px;
    }

    .hover-card:nth-child(1) {
        background-color: #b881ff;
    }

    .hover-card:nth-child(2) {
        background-color: #ff9950;
    }

    .hover-card:nth-child(3) {
        background-color: #aaec8a;
    }

    .hover-card:nth-child(4) {
        background-color: #ed6a5e;
    }

    .hover-card:not(:first-child) {
        margin-left: -55px;
    }

    .hover-card:hover {
        transform: translateY(-26px);
        font-weight: bold;
        cursor: pointer;
    }

    .hover-card:not(:hover) {
        left: 55px;
    }
}