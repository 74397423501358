:root {
    --skeleton: #656871;
}

.skeleton-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    background: #11131e;
    width: 50%;
    margin: 10px;

    .card-skeleton {
        max-width: 350px;
        width: 100%;
        background: #11131e;
        padding: 30px;
        border-radius: 10px;
        border: 1px solid var(--skeleton);
        box-shadow: 0px 1px 26px -10px white;

        .header {
            display: flex;
            align-items: center;

            .img {
                height: 75px;
                width: 75px;
                background: var(--skeleton);
                border-radius: 50%;
                position: relative;
                overflow: hidden;
            }

            .details {
                margin-left: 20px;

                span {
                    display: block;
                    background: var(--skeleton);
                    border-radius: 10px;
                    overflow: hidden;
                    position: relative;
                }

                .name {
                    height: 15px;
                    width: 100px;
                }

                .about {
                    height: 13px;
                    width: 150px;
                    margin-top: 10px;
                }
            }
        }

        .description {
            margin: 25px 0;
            
            .line {
                background: var(--skeleton);
                border-radius: 10px;
                height: 13px;
                margin: 10px 0;
                overflow: hidden;
                position: relative;
            }

            .line-1 {
                width: calc(100% - 15%);
            }

            .line-3 {
                width: calc(100% - 40%);
            }
        }

        .btns {
            display: flex;

            .skeleton-btn {
                height: 45px;
                width: 100%;
                background: var(--skeleton);
                border-radius: 25px;
                position: relative;
                overflow: hidden;
                margin: 0 5px;
            }
        }
    }
}

@media (max-width: 1000px) {
    .skeleton-container {
        width: 95%;
    }
}