.project-page-container {
    overflow-x: hidden;
    width: 100%;

    .page-text-container {
        width: 80%;
        margin: 0 auto;
        margin-top: 5%;

        .page-title {
            font-size: 30px;
            text-align: center;
        }

        .page-text {
            font-size: 24px;
        }
    }

    .divider {
        width: 50%;
        margin-top: 50px;
        border-top: 3px solid rgba(57, 136, 179, 0.5);
    }
}